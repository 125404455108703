import React, { useState, useEffect, useRef } from 'react'
import './rarity.css'

export default function Rarity() {
    const containerRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)
  
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
    const startAnimation = (entries, observer) => {
        const [ entry ] = entries
        setIsVisible(entry.isIntersecting)

        if (isVisible) {
            const elements = document.getElementsByClassName('rectangle')
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.add('animation')
            }
        }
        else{
            const elements = document.getElementsByClassName('rectangle')
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('animation')
            }
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(startAnimation, options);
        const cuurentRef = containerRef.current
        if(cuurentRef) observer.observe(cuurentRef)
        return () => {
            if(cuurentRef) observer.unobserve(cuurentRef)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef, options])


    return (
        <div className='rarityBase' id='rarity'>
            <div className="title">RARITY</div>
            <div className="rarityContainer" ref={containerRef}>
                {/* <img src={raritySVG} alt="" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="1360" height="359" viewBox="0 0 1360 359">
                    <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(62 284)" fill="none" stroke="#fff" strokeWidth="1">
                        <rect width="1298" height="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="1297" height="4" fill="none" />
                    </g>
                    <g id="Rectangle_12" data-name="Rectangle 12" transform="translate(58 18)" fill="none" stroke="#fff" strokeWidth="1">
                        <rect width="5" height="271" stroke="none" />
                        <rect x="0.5" y="0.5" width="4" height="270" fill="none" />
                    </g>
                    <text id="GEN_1" data-name="GEN 1" transform="translate(381 354)" fill="#555555" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-23.872" y="0">GEN 1</tspan></text>
                    <text id="GEN_2" data-name="GEN 2" transform="translate(600 354)" fill="#777777" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-20.872" y="0">GEN 2</tspan></text>
                    <text id="GEN_3" data-name="GEN 3" transform="translate(818 354)" fill="#999999" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-23.872" y="0">GEN 3</tspan></text>
                    <text id="GEN_4" data-name="GEN 4" transform="translate(1037 354)" fill="#bbbbbb" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-23.872" y="0">GEN 4</tspan></text>
                    <text id="GEN_0" data-name="GEN 0" transform="translate(163 354)" fill="#dddddd" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-23.872" y="0">GEN 0</tspan></text>
                    <text id="GEN_5" data-name="GEN 5" transform="translate(1255 354)" fill="#ffffff" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-23.872" y="0">GEN 5</tspan></text>
                    
                    <rect className = "rectangle" id="Rectangle_14" data-name="Rectangle 14" width="89" height="267" x="333" y="18" fill="#555555" />
                    <rect className = "rectangle" id="Rectangle_18" data-name="Rectangle 18" width="89" height="144" x="560" y="140" fill="#777777" />
                    <rect className = "rectangle" id="Rectangle_17" data-name="Rectangle 17" width="89" height="91" x="770" y="193" fill="#999999" />
                    <rect className = "rectangle" id="Rectangle_16" data-name="Rectangle 16" width="89" height="39" x="993" y="245" fill="#bbbbbb" />
                    <rect className = "rectangle" id="Rectangle_13" data-name="Rectangle 13" width="89" height="21" x="119" y="264" fill="#dddddd" />
                    <rect className = "rectangle" id="Rectangle_15" data-name="Rectangle 15" width="89" height="8" x="1211" y="277" fill="#ffffff" />
                    
                    <text id="_5000" data-name="5000" transform="translate(27 27)" fill="#555555" fontSize="25" fontFamily="SegoeUI, Segoe UI"><tspan x="-26.953" y="0">2400</tspan></text>
                    <text id="_2900" data-name="2900" transform="translate(23 149)" fill="#777777" fontSize="20" fontFamily="SegoeUI, Segoe UI"><tspan x="-21.563" y="0">1500</tspan></text>
                    <text id="_1500" data-name="1500" transform="translate(27 202)" fill="#999999" fontSize="17" fontFamily="SegoeUI, Segoe UI"><tspan x="-18.328" y="0">1000</tspan></text>
                    <text id="_475" data-name="475" transform="translate(33 249)" fill="#bbbbbb" fontSize="15" fontFamily="SegoeUI, Segoe UI"><tspan x="-12.129" y="0">475</tspan></text>
                    <text id="_100" data-name="100" transform="translate(33 269)" fill="#dddddd" fontSize="12" fontFamily="SegoeUI, Segoe UI"><tspan x="-9.703" y="0">100</tspan></text>
                    <text id="_25" data-name="25" transform="translate(33 281)" fill="#ffffff" fontSize="10" fontFamily="SegoeUI, Segoe UI"><tspan x="-5.391" y="0">25</tspan></text>
                    <rect id="Rectangle_19" data-name="Rectangle 19" width="5" height="3" transform="translate(60 285)" fill="#271e1e" />
                </svg>

            </div>
        </div>
    )
}
