import React from 'react'
import './footer.css'

export default function Footer() {
    return (
        <div className = 'footerBase'>
            <div className="socials">
                <div className="discord">
                    <a rel="noopener noreferrer" href='https://discord.gg/fBpUDn6N85' target="_blank">
                        Discord
                    </a>
                </div>
                <div className="twitter">
                    <a rel="noopener noreferrer" href='https://twitter.com/avarsas' target="_blank">
                        Twitter
                    </a>
                </div>
                <div className="contract">
                    <a rel="noopener noreferrer" href='https://cchain.explorer.avax.network/address/0xfE324688283b527E91cb85F17E6Ac16684e66044/contracts' target="_blank">
                        Contract
                    </a>
                </div>
            </div>
        </div>
    )
}
