import React,{useState, useEffect, useContext} from 'react'
import AppContext from '../AppContext';
import ReactPaginate from 'react-paginate'
import data from '../../resources/metadata.json'
import avaxLogo from '../../resources/avax.svg'
import Canvas from '../Canvas/Canvas'
import './burn.css'
export default function Burn() {
    const context = useContext(AppContext)
    const [items] = useState(data);
    const [displayItems, setDisplayItems] = useState()
    const [itemsLength, setItemsLength] = useState(0)
    const [itemsFiltered,setFilteredItems] = useState()
    //! pagination variables
    const [pageNumber, setPageNumber] = useState(0)
    const itemsPerPage = 4
    const pagesViseted = pageNumber * itemsPerPage

    const burnToken = async (e) => {
        try {
            const hash = await context.contract.burn(e)
            console.log(hash);    
        } catch (error) {}
    }

    useEffect(() => {
        try {
            let list = []
            let len = 0
            context.walletOfOwner.map( token => {
                const itemId = token.toString()
                if (itemId % 50 === 0 && itemId > 500) {
                    len++
                    list.push(token)
                }
                return null
            })
            setItemsLength(len)
            setFilteredItems(list)
        } catch (error) {}
    },[context.walletOfOwner])

    useEffect(() => {
        const getWalletOfOwner = async () => {
            try {
                const displayItems = itemsFiltered.slice(pagesViseted, pagesViseted+itemsPerPage).map( token => {
                    const itemId = token.toString()
                    const editionID = itemId -1
                    const metaData = items[itemId-1]
                    return(
                        <div className="item rgb" key = {itemId}>
                            <div className="canvasContainer" id = {editionID}>
                                <Canvas edition = {editionID} id={editionID.toString()} name={'showCanvas'+editionID} width = {350} height = {350} mirror={false} segments={400} resize={false}/>
                            </div>
                            <div className ='details'>
                                <div className = 'title'>{metaData.name} <span className="lvl">GEN {metaData.attributes[8].value}</span> </div>
                                <div className = 'features'>
                                </div>
                                <div className = 'burnSection'>
                                    <span className = 'price'>
                                        5
                                        <img src={avaxLogo} alt =''/>
                                    </span>
                                    <span className = 'burnButton' onClick = {() => burnToken(itemId)}>BURN</span>
                                </div>
                            </div>
                        </div>
                    )

                })
                setDisplayItems(displayItems)
            } catch (error) {}
        }
        getWalletOfOwner()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[itemsFiltered, pageNumber])
    
    const pageCount = Math.ceil(itemsLength / itemsPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }
    const pagination = () => {
        return (
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                perRangeDisplayed={1}
                marginPagesDisplayed={1}
                forcePage={pageNumber}
                pageClassName={pageNumber}
            />
        )
    }

    return (
        <div className = 'burnBase' id ='burner'>
            <div className="maintitle">
                BURN
            </div>
            <div className="burnContainer">
                {itemsLength?(itemsLength > 4) ? pagination() :null :null}
                <div className="itemsGrid">
                    {displayItems? 
                    (displayItems.some((item) => item !== null) === true)?
                    displayItems:
                    <span className = 'noBurn'>You do not own any GEN 0 Tokens to burn</span>
                    :<span className = 'noBurn'>Wallet is not Connected</span>
                    }
                </div>
            </div>
        </div>
    )
}
