import React from 'react'
import Canvas from '../Canvas/Canvas'
import './about.css'
//!========================================================= 

export default function About() {

    let quality = 500
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        quality = 250
    }

    return (
        <div className ='aboutBase'>
            <div className = 'aboutContainer'>
                <div className="left">
                    <div className="title">ABOUT AVARSAS</div>
                    <p className="description">
                        Avarsas are 5,500 randomly generated NFTs from code using
                        shaders and rendered realtime on the GPU
                        living on the Avalanche blockchain.
                        each Avarsa is uniquely customized, and there are about infinite version.
                    </p>
                </div>
                <div className="right" id='aboutUs'>
                    <Canvas edition={4274} id = 'aboutUs' name='canvas2' mirror={false} segments={quality} resize={true}/>
                </div>
            </div>
        </div>
    )
}
