import React, { useEffect, useState, useRef } from 'react'
import * as Three from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Plane from './Plane'
import data from '../../resources/metadata.json'
import * as U from './utils.js'

export default function Canvas(props) {
    const [items] = useState(data);
    const canvasRef = useRef();

    useEffect(() => {
        let mounted = false
        let animationId,scene,controls,plane, camera, renderer
        try {
            const canvas = document.getElementById(props.name)
            let w = document.getElementById(props.id).clientWidth
            let h = document.getElementById(props.id).clientHeight

            //! create scene
            scene = new Three.Scene()

            //! create and add camera
            camera = new Three.PerspectiveCamera(25, w / h, 0.1, 1000)
            camera.position.z = 8// was 9
            scene.add(camera)

            //! create renderer on canvas
            renderer = new Three.WebGLRenderer({
                canvas: canvas,
                antialias: true,
                alpha:true,
            })
            renderer.setClearColor( 0x271E1E, 0);
            renderer.setSize(w, h)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            renderer.outputEncoding = Three.sRGBEncoding;
            document.getElementById(props.id).prepend(renderer.domElement);            

            //! add orbit controls
            controls = new OrbitControls(camera,renderer.domElement)
            controls.enablePan = false
            controls.enableZoom = false
            controls.maxPolarAngle = Math.PI / 2
            controls.update()

            let no = props.edition
            let item = items[no].attributes
            plane = new Plane(item[0].value,item[1].value,item[2].value,item[3].value,
                item[4].value,item[5].value,item[6].value,item[7].value, props.segments,item[8].value )
            const current0 = Date.now()
            //! start rendering
            if(props.mirror){
                U.addMirrorWorld(scene,-1.5, - Math.PI / 2)
            }
            plane.addPlane(scene)
            const animate = () => {
                if (mounted) {
                    return
                }
                try {
                    if (props.resize) {
                        w = document.getElementById(props.id).clientWidth
                        h = document.getElementById(props.id).clientHeight
                        renderer.setSize(w, h)  
                    }   
                } catch (error) {}
                animationId = requestAnimationFrame(animate)
                let current = Date.now()
                let elapsedTime = current - current0;
                plane.update(elapsedTime)
                renderer.render(scene, camera)
            }
            renderer.render(scene, camera)

            animate()    
        } catch (error) {
            
        }
        
        return () => {
            try{
                mounted = true
                cancelAnimationFrame(animationId)
                scene.remove(camera)
                camera = null
                plane.delete(scene)
                if(props.mirror) U.disposeMirror(scene)
                // renderer.forceContextLoss();
                renderer.domElement = null;
                renderer.dispose()
                renderer = null;
                scene = null
            }
            catch(error){
            }
            

        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.segments])

    return (
        <canvas id = {props.name} ref={canvasRef}/>
    )
}
