import React from 'react'
import './tokenomics.css'
import reward from '../../resources/1x/Reward.png'
import burn from '../../resources/1x/Burn.png'
import royalty from '../../resources/1x/Royalty.svg'
import gift from '../../resources/1x/Gift.png'
import gear from '../../resources/1x/Gear.png'

export default function Tokenomics() {
  return (
    <div className="tokenomicsBase">

      <div className="title">TOKENOMICS</div>

      <div className="tokenomicsContainer">

        <div className="left">
          <div className="icon">
            <img src={reward} alt="reward" />
          </div>
          <div className="info">
            <div className="title">Generate Income</div>
            <div className="text">
            2% of each sale will be distributed to all the tokens
            in the circulating supply and can be claimed by the owner, if the token was transferred before the reward was claimed the new owner will inherit the reward, and
            if the token gets burned without claiming the reward, the reward will be redistributed to all the holders 
            </div>
          </div>
        </div>

        <div className="right">
          <div className="icon">
            <img src={burn} alt="burn" />
          </div>
          <div className="info">
            <div className="title">Deflationary</div>
            <div className="text">
              0.75% of each sale will go to a burn pool once this burn pool
              matches the floor price the contract will burn the least token for sale in number and
              price
            </div>
          </div>
        </div>

      </div>

      <div className="tokenomicsContainer2">

        <div className="item1">
          <div className="icon">
            <img src={royalty} alt="reward" />
          </div>
          <div className="info">
            <div className="title">Royality</div>
            <div className="text">
              Each minter will receive a 1% royalty on
              every sale until the token is burned
            </div>
          </div>
        </div>

        <div className="item2">
          <div className="icon">
            <img src={gift} alt="burn" />
          </div>
          <div className="info">
            <div className="title">Special Tokens</div>
            <div className="text">
            100 GEN 0 Tokens will be unique once burned their owners will
            receive 5 AVAX reward
            </div>
          </div>
        </div>

        <div className="item3">
          <div className="icon">
            <img src={gear} alt="burn" />
          </div>
          <div className="info">
            <div className="title">Development</div>
            <div className="text">
            0.25% of each sale will be reflected to the contract owner to run
            and develop the project further
            </div>
          </div>
        </div>

      </div>
      
    </div>
  )
}
