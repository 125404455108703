import React from 'react'
import './roadmap.css'
import roadmapSVG from '../../resources/Roadmap.svg'
export default function RoadMap() {
    return (
        <div className = 'roadmapBase'>
            <div className="title">
                ROADMAP
            </div>
            <div className="roadmapContainer">
                <img src={roadmapSVG} alt="roadmapsvg" />
            </div>
        </div>
    )
}
