import React,{useState, useEffect} from 'react'
import { ethers } from "ethers";
import './navbar.css'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom';

export default function Navbar() {

  const [showNav,setShowNav] = useState(false)
  const [iconChange,setIconChange] = useState(false)
  const [accounts,setAccounts] = useState()
  const [errorMessage, setErrorMessage] = useState(0)

  useEffect(() => {
    const connect = async () => {
      try {
        if (window.ethereum && window.ethereum.isMetaMask) {
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (chainId.toString() === '0xa86a') { // 1= fuji testnet , 2- mainnet
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const accounts = await provider.listAccounts() 
            if (accounts.length > 0) {
              setAccounts(accounts)    
              setErrorMessage(-1) // success      
            }
            else{setErrorMessage(3)} // connect Wallet
          }
          else{setErrorMessage(2)} // chainId is not Avax
        } 
        else{setErrorMessage(1)} // metaMask Not Installed
      } catch (error) {}
    }
    connect()
  },[])

  // if wallet changed trigger this function
  try {
    window.ethereum.on('accountsChanged', async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await provider.listAccounts() 
        if (accounts.length > 0) {
          setAccounts(accounts)  
          window.location.reload()
        }
      } catch (error) {
      }
    })
  
    window.ethereum.on('chainChanged',async () => {
      try {
        window.location.reload();
      } catch (error) {}
    })
  } catch (error) {}
  

  //connect wallet to the site on click
  const connectWallet = async () => {
      if (window.ethereum && errorMessage === 3) {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setErrorMessage(-1)//success
            setAccounts(accounts)
            window.location.reload()      
        } catch (error) {
            if (error.code === 4001) {
                document.getElementById('walletBtn').innerHTML = 'Rejected'
            }
            else {
                document.getElementById('walletBtn').innerHTML = 'Oops'
            }
        }
    }
  }

  return (
    <div className="navBar">
      <div className="navBarWrapper">
        <div className="nav-logo">
          <span className="logo">
            <Link to ='/'>
            AVARSAS
            </Link>
            </span>
        </div>
        <div className="nav-menues" id={showNav?"hidden1":""}>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false)}} to = '/' >Home</NavLink>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false)}} to = '/showroom' >Show Room</NavLink>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false)}} to = '/museum' >Museum</NavLink>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false)}} to = '/market' >Market</NavLink>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false);try {const anchor = document.querySelector('#burner');anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });} catch (error) {}}} to = '/' >Burn</NavLink>
          <NavLink onClick = {() => {setShowNav(false);setIconChange(false)}} to = '/' className='NavplaceHolder'></NavLink>
        </div>
        <div className="nav-special" id={showNav? "hidden2":""}>
          <button onClick = {connectWallet} id='walletBtn'>
          {
            errorMessage === 1 ? 'Install MetaMask':
            errorMessage === 2 ? 'Change Network':
            errorMessage === 3 ? 'Connect Wallet':
            accounts?
            accounts[0]?accounts[0].toString().slice(0,6).toUpperCase()  +'...'+ accounts[0].toString().slice(-4).toUpperCase():'Connect Wallet'
            :'Connect Wallet'
          }
          </button>
        </div>
        <div className="burger">
          <div className="burgerIcon" id={iconChange?'iconChange':''} onClick = {() => {setShowNav(!showNav);setIconChange(!iconChange)}}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
