import * as THREE from 'three/build/three.module.js'
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';
import leafAlpha from './static/leafAlpha.png'
import Plane from './Plane.js'
import Sphere from './Sphere.js'
import data from '../../resources/metadata.json'

let mirrorGeometry,mirrorMesh
export const addMirrorWorld = (scene, y, r) => {

    mirrorGeometry = new THREE.PlaneGeometry(1600, 700)
    mirrorMesh = new Reflector(mirrorGeometry, {
        clipBias: 0.0003,
        textureWidth: window.innerWidth * window.devicePixelRatio,
        textureHeight: window.innerHeight * window.devicePixelRatio,
        color: 0x777777
    });
    mirrorMesh.position.y = -0.05 + y;
    mirrorMesh.rotateX(r);
    scene.add(mirrorMesh);
}
export const disposeMirror = (scene) =>{
    scene.remove(mirrorMesh)
    mirrorGeometry.dispose()
}


export const addNFT = (x, y, z, w, h, edition, scene,type) => {
    let no = edition
    let item = data[no].attributes
    if(type === 1){
        const plane = new Plane(item[0].value,item[1].value,item[2].value,item[3].value,item[4].value,item[5].value,item[6].value,item[7].value,w,h,item[8].value)
        plane.movePlane(x,y,z)
        plane.addPlane(scene)
        return plane
    }
    else if(type === 2){
        const sphere = new Sphere(item[0].value,item[1].value,item[2].value,item[3].value,item[4].value,item[5].value,item[6].value,item[7].value,w,h,item[8].value)
        sphere.moveSphere(x,y,z)
        sphere.addSphere(scene)
        return sphere
    }
}

export class ParticlesLeaves {
    constructor(color){
        this.particlesGeometry = new THREE.BufferGeometry()
        this.amount = 300000
        this.particlesPosition = new Float32Array(this.amount * 3)

        this.setPosition()
        this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(this.particlesPosition, 3))
        this.particlesTexture = new THREE.TextureLoader().load(leafAlpha)
        this.particlesTexture.encoding = THREE.sRGBEncoding;
        this.particlesMaterial = new THREE.PointsMaterial({
            size: 0.1,
            transparent: true,
            color: color,
            alphaMap: this.particlesTexture,
            depthWrite: false,
    
        })
        this.particlesMesh = new THREE.Points(this.particlesGeometry, this.particlesMaterial)

    }

    setPosition(){
        for (let i = 0; i < this.amount * 3; i += 3) {
            this.particlesPosition[i] = (Math.random() - 0.5) * (1600)
            this.particlesPosition[i + 1] = (Math.random() - 0.5) * (20) + 10
            this.particlesPosition[i + 2] = (Math.random() - 0.5) * (1600)
        }
    }

    addTo(scene){
        scene.add(this.particlesMesh)
    }
    dispose(scene){
        scene.remove(this.particlesMesh)
        this.particlesGeometry.dispose()
        this.particlesMaterial.dispose()
        this.particlesTexture.dispose()
    }
    update(deltaTime){
        this.particlesMesh.rotation.y += (0.00002 * deltaTime)

    }
}

export const resizeWindow = (size, camera, renderer) => {
    window.addEventListener('resize', () => {


        size.width = window.innerWidth
        size.heigth = window.innerHeight

        camera.aspect = size.width / size.heigth
        camera.updateProjectionMatrix()

        renderer.setSize(size.width, size.heigth)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
}

export const addFullWindowSupport = (canvas) => {
    document.getElementById('fullScreen').addEventListener('click', () => {
        const fullScreen =
            document.fullscreenElement || document.webkitFullscreenElement

        if (!fullScreen) {
            if (canvas.requestFullScreen) {
                canvas.requestFullScreen()
            } else if (canvas.webkitRequestFullScreen) {
                canvas.webkitRequestFullScreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        }
    })
}