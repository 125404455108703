import * as THREE from 'three'
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';

let mirrorGeometry,mirrorMesh
export const addMirrorWorld = (scene, y, r) => {

    mirrorGeometry = new THREE.PlaneGeometry(20, 20)

    mirrorMesh = new Reflector(mirrorGeometry, {
        clipBias: 0.0003,
        textureWidth: window.innerWidth * window.devicePixelRatio,
        textureHeight: window.innerHeight * window.devicePixelRatio,
        color: 0x999999
    });
    mirrorMesh.position.y = -0.05 + y;
    mirrorMesh.rotateX(r);
    scene.add(mirrorMesh);
}

export const disposeMirror = (scene) =>{
    scene.remove(mirrorMesh)
    mirrorGeometry.dispose()
}

export const resizeWindow = (camera, renderer , w, h) => {
    window.addEventListener('resize', () => {
        camera.aspect = w / h
        camera.updateProjectionMatrix()
        renderer.setSize(w, h)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
}
