import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import './marketPlace.css'
import data from '../../resources/metadata.json'
import avaxLogo from '../../resources/avax.svg'
import ABI from '../../resources/ABI.json'
import { ethers } from "ethers";
import Canvas from '../Canvas/Canvas'
import {mainAddress} from '../contractAddress.js'


export default function MarketPlace() {
    
    let isMobile = false
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
    }

    const contractAddress = mainAddress
    const [contract, setContract] = useState()

    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const signer = provider.getSigner()
                    const contract = new ethers.Contract(contractAddress, ABI, signer)
                    setContract(contract)
                } catch (error) {}
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [floorPrice, setFloorPrice] = useState()
    const [floorToken, setFloorToken] = useState()
    const [totalVolume, setTotalVolume] = useState()
    const [totalListings, setTotalListings] = useState()
    const [totalSales, setTotalSales] = useState()
    const [totalBurned, setTotalBurned] = useState()
    const [tokenReward, setTokenReward] = useState()
    const [burnPool, setBurnPool] = useState()
    const [allListingPrices, setAllListingPrices] = useState()
    const [allLastSoldPrices, setLastSoldPrices] = useState()
    const [allLiveStatus, setAllLiveStatus] = useState() 
    const [allBurnedStatus, setAllBurnedStatus] = useState()
    const [totalMinted, setTotalMinted] = useState(20)
    const [allListingDates,setAllListingDates] = useState()
    const [allSoldDates, setAllSoldDates] = useState()

    useEffect(() => {
        const getTotalVolume = async () => {//return type: single number
            try {
                const v = await contract._TotalVolume()
                setTotalVolume(v)

            } catch (error) { }
        }
        const getTotalListings = async () => {//return type: single number
            try {
                const l = await contract._itemIds()
                setTotalListings(l)
            }
            catch (error) { }
        }
        const getTotalSales = async () => {//return type: single number
            try {
                const s = await contract._itemsSold()
                setTotalSales(s)
            }
            catch (error) { }
        }
        const getTotalBurnedAndSupply = async () => {//return type: single number
            let b,supply
            try {
                b = await contract.itemsBurned()
                setTotalBurned(b)
            }
            catch (error) {}
            try {
                supply = await contract.totalSupply()
            } catch (error) {}

            try {
                setTotalMinted(parseInt(b.toString()) + parseInt(supply.toString()))
            } catch (error) {}
        }

        const getBurnPool = async () => {//return type: single number
            try {
                const burnPool = await contract.burnPool()
                setBurnPool(burnPool)
            } catch (error) { }
        }
        getTotalVolume()
        getTotalListings()
        getTotalSales()
        getTotalBurnedAndSupply()
        getBurnPool()
    }, [contract])
    // 1
    useEffect(() => {
        
        const getAllRewards = async () => {//return type: array of tokens reward from 0 to 5499
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('getting token rewards, Attempt: ' + count);
                    const rewards = await contract.getAllTokenRewards()
                    setTokenReward(rewards)
                    if (rewards !== undefined) break;
                } catch (error) { 
                    if (++count === maxTries) break;
                }
            }
        }
        getAllRewards()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[burnPool])

    // 2
    useEffect(() => {
        const getAllLiveStatus = async () => {//return type: array of booleans true live in market, false not live starts frpm 0 to 54999
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('getting Listed Tokens, Attempt: ' + count);
                    const status = await contract.getAllLiveStatues()
                    setAllLiveStatus(status)
                    break;
                } catch (error) { 
                    if (++count === maxTries) break;
                }
            }
        }
        getAllLiveStatus()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[tokenReward])

    // 3
    useEffect(() => {
        const getAllBurnedStatus = async () => {
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('getting Burned Tokens, Attempt: ' + count);
                    const status = await contract.getAllBurnedStatues()
                    setAllBurnedStatus(status)
                    break;
                } catch (error) {
                    if (++count === maxTries) break;
                }
            }
        }
        getAllBurnedStatus()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allLiveStatus])

    // 4
    useEffect(() => {
        const getAllSoldDates = async () => {
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('getting Sell Dates, Attempt: ' + count);
                    const list = []
                    const dates = await contract.getAllSoldDates()
                    for (let i = 0; i < dates.length; i++) {
                        list.push({
                            tokenId:i,
                            soldDate:dates[i]})
                    }
                    setAllSoldDates(list)
                    break;
                } catch (error) {
                    if (++count === maxTries) break;
                }
            }
        }
        getAllSoldDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allBurnedStatus])

    // 5
    useEffect(() => {
        const getAllListDates = async () => {
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('getting List Dates, Attempt: ' + count);
                    const list = []
                    const dates = await contract.getListingDates()
                    for (let i = 0; i < dates.length; i++) {
                        list.push({
                            tokenId:i,
                            listDate:dates[i]})
                    }
                    setAllListingDates(list)
                    break;
                } catch (error) {
                    if (++count === maxTries) break;
                }
            }
        }
        getAllListDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allSoldDates])

    // 6
    useEffect(() => {
        const getFloorPriceAndToken = async () => {//return type: single number
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('Finding Floor price, Attempt: ' + count);
                    const lo = await contract.getLowestOffer();
                    setFloorToken(lo)
                    const lp = await contract.tokenIdTolistedTokenInfo(lo.toString())
                    setFloorPrice(lp.price)
                    break
                }
                catch (error) {
                    if (++count === maxTries) break;
                }
            }
        }
        getFloorPriceAndToken()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allListingDates])

    // 7
    useEffect(() => {
        const getAllPrices = async () => {//return type: array of objects {id from 0 ,listingprice}from 0 to 5499
            let count = 0;
            const maxTries = 7;
            while (true) {
                try {
                    // console.log('Getting Listing prices, Attempt: ' + count);
                    const list = []
                    const prices = await contract.getAllPrices()
                    for (let i = 0; i < prices.length; i++) {
                        list.push({
                            tokenId:i,
                            price:prices[i]})
                    }
                    setAllListingPrices(list)
                    break;
                } catch (error) {
                    if (++count === maxTries) break;
                 }
            }
        }
        getAllPrices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[floorPrice])

    // 8
    useEffect(() => {
        
        const getAllLastSold = async () => {//return type: array of objects {id from 0 ,lastSoldPrice}from 0 to 5499
            let count = 0
            const maxTries = 7
            while (true) {
                try {
                    // console.log('Getting LastSold prices, Attempt: ' + count);
                    const list = []
                    const lastSoldPrices = await contract.getAllLastSold()
                    for (let i = 0; i < lastSoldPrices.length; i++) {
                        list.push({
                            tokenId:i,
                            lastSold:lastSoldPrices[i]})
                    }
                    setLastSoldPrices(list)
                    break
                } catch (error) { 
                    if (++count === maxTries) break;
                }
            }
        }
        getAllLastSold()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allListingPrices])


    //! pagination variables
    const [items] = useState(data);
    const [pageNumber, setPageNumber] = useState(0)
    const itemsPerPage = isMobile?4:9
    const pagesViseted = pageNumber * itemsPerPage
    const [paginationLength,setPaginationLength] = useState(0)

    //! market and filter variables
    const [renderMarketItems, setRenderMarketItems] = useState()
    const [sortByValue,setSortByValue] = useState(0)
    const [quality,setQuality] = useState(isMobile?200:400)


    const buyToken = async (token) => {
        try {
            const price = allListingPrices[token - 1].price
            let overrides = {
                value: price
            }
            const hash = await contract.buyMarketItem(token,floorToken, overrides);
            console.log(hash);
        } catch (error) {
            console.log('buying item failed');
        }
    }

    const renderItem = (thisEditionId0) => {
        // zero because its zero indexed

        const thisTokenReward = tokenReward?
        parseFloat(ethers.utils.formatUnits(tokenReward[thisEditionId0]? tokenReward[thisEditionId0].toString() : 0, 18)).toFixed(8)
        : '0'

        const thisTokenBurnedStatus = allBurnedStatus?allBurnedStatus[thisEditionId0]:false


        const thisListingPrice = allListingPrices?
        allListingPrices[thisEditionId0]?
        (ethers.utils.formatUnits(allListingPrices[thisEditionId0].price, 18) > 0.0?
         ethers.utils.formatUnits(allListingPrices[thisEditionId0].price, 18)
        : 'N.L')
        : '....' 
        : 'Loading..'

        const thisLastSoldPrice = allLastSoldPrices?
        allLastSoldPrices[thisEditionId0]?
        ethers.utils.formatUnits(allLastSoldPrices[thisEditionId0].lastSold, 18) 
        : '....' 
        : 'Loading..'

        const thisGen = items[thisEditionId0].attributes[8].value
        
        const tokenId1 = thisEditionId0 + 1// tokenId1 is 1 indexed for buy order and display the token name
        return (
            <div className="item rgb"  key = {thisEditionId0}>
                <div className="marketCanvasContainer" id ={thisEditionId0}>

                    <Canvas 
                    edition = {thisEditionId0} 
                    id={thisEditionId0.toString()} //the id of the parent to append to it
                    name={'marketCanvas'+thisEditionId0} 
                    mirror={false} 
                    segments={quality} 
                    resize={true}
                    />

                </div>
                <div className='details'>
                    <div className='title'>#{tokenId1}<span className="lvl">GEN {thisGen}</span></div>
                    <div className="selectedReward">
                        {thisTokenBurnedStatus?
                        'BURNED': 'Token Reward:' + thisTokenReward}
                         {!thisTokenBurnedStatus && <img src={avaxLogo} alt='logo'/>}
                    </div>
                    <div className="buyButton">
                        <div className='buySection'>
                            <span className='price'>
                                <span className="p">Price </span>
                                {thisListingPrice}
                                <img src={avaxLogo} alt='' />
                            </span>
                            <span className="lastSoldPrice">
                                <span className="l">Last </span>
                                {thisLastSoldPrice}
                                <img src={avaxLogo} alt='' />
                            </span>
                        </div>
                        {!thisTokenBurnedStatus &&
                        <button onClick={() => buyToken(tokenId1)} >BUY</button>}
                    </div>
                </div>
            </div>
        );
    }
// 3,2,1,4,5,6 newest sell to oldest
    const bTf = (number) => {
        return parseFloat(number.toString())
    }
    useEffect(() => {
        
        const byMinted = (item) => {
            if(item.edition <= totalMinted) return true
            return false
        }
        const sortByAll = (listedOnly) => {
            try {
                if(listedOnly){
                    const byLiveStatus = (item) => {
                        return allLiveStatus[item.name.substring(1) - 1]
                    }
                    const mintedItems = items.filter(byMinted)
                    const filteredItems = mintedItems.filter(byLiveStatus)
                    setPaginationLength(filteredItems.length)

                    const renderedItems = filteredItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                        const editionId0 = item.edition - 1
                        return(renderItem(editionId0))
                    })
                    setRenderMarketItems(renderedItems)
                }
                else{
                    const mintedItems = items.filter(byMinted)
                    setPaginationLength(mintedItems.length)
                    const displayItems = mintedItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                        const editionId0 = item.edition - 1
                        return(renderItem(editionId0))
                    })
                    setRenderMarketItems(displayItems)
                }
            } catch (error) {}
        }

        const sortByPrice = (HighToLow) => {
            try {
                const byLiveStatus = (item) => {
                    return allLiveStatus[item.tokenId]
                }
                const filteredItems = allListingPrices.filter(byLiveStatus)
                let sortedItems
                if(HighToLow) sortedItems = filteredItems.sort((a,b) => (bTf(a.price) > bTf(b.price))? -1:1)
                else sortedItems = filteredItems.sort((a,b) => (bTf(a.price) > bTf(b.price))? 1:-1)
                setPaginationLength(sortedItems.length)
                
                const renderedItems = sortedItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                    const editionId0 = item.tokenId // here it starts from zero
                    return(renderItem(editionId0))
                    
                })
                setRenderMarketItems(renderedItems)
            } catch (error) {}
        }

        const sortByLastSold = (HighToLow) => {
            try {
                const byPriceNotZero = (item) => {
                    return item.lastSold > 0 
                }

                const filteredItems = allLastSoldPrices.filter(byPriceNotZero)
                let sortedItems
                if(HighToLow) sortedItems = filteredItems.sort((a,b) => (bTf(a.lastSold) > bTf(b.lastSold))? -1:1)
                else sortedItems = filteredItems.sort((a,b) => (bTf(a.lastSold) > bTf(b.lastSold))? 1:-1)
                setPaginationLength(sortedItems.length)
                const renderedItems = sortedItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                    const editionId0 = item.tokenId // here it starts from zero
                    return(renderItem(editionId0))
                    
                })
                setRenderMarketItems(renderedItems)
            } catch (error) {}
        }

        const sortByRarity = (gen) => {
            try {
                const byGen = (item) => {
                    return item.attributes[8].value === gen
                }
                const mintedItems = items.filter(byMinted)
                const filteredItems = mintedItems.filter(byGen)
                setPaginationLength(filteredItems.length)
                const renderedItems = filteredItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                    const editionId0 = item.edition - 1
                    return(renderItem(editionId0))
                })
                setRenderMarketItems(renderedItems)
            } catch (error) {
            }
        }

        const sortByBurned = () => {
            try {
                const byBurnedStatus = (item) => {
                    return allBurnedStatus[item.edition - 1]
                }
                const filteredItems = items.filter(byBurnedStatus)
                setPaginationLength(filteredItems.length)
                const renderedItems = filteredItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                    const editionId0 = item.edition - 1
                    return(renderItem(editionId0))
                })
                setRenderMarketItems(renderedItems)
            } catch (error) {}
        }

        const sortByRecentlyListed = (inverted) => {
            try {
                    const byLiveStatus = (item) => {
                        return allLiveStatus[item.tokenId]
                    }
                    const filteredItems = allListingDates.filter(byLiveStatus)
                    let sortedItems
                    if (inverted)  sortedItems = filteredItems.sort((a,b) => (bTf(a.listDate) > bTf(b.listDate))? 1:-1)
                    else sortedItems = filteredItems.sort((a,b) => (bTf(a.listDate) > bTf(b.listDate))? -1:1)
                    setPaginationLength(sortedItems.length)
                    const renderedItems = sortedItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                        const editionId0 = item.tokenId // here it starts from zero
                        return(renderItem(editionId0))
                        
                    })
                    setRenderMarketItems(renderedItems)
            } catch (error) {
            }
        }

        const sortByRecentlySold = (inverted) => {
            try {
                    const byPriceNotZero = (item) => {
                        return bTf(allLastSoldPrices[item.tokenId].lastSold) > 0 
                    }

                    const filteredItems = allSoldDates.filter(byPriceNotZero)
                    let sortedItems
                    if (inverted)  sortedItems = filteredItems.sort((a,b) => (bTf(a.soldDate) > bTf(b.soldDate))? 1:-1)
                    else sortedItems = filteredItems.sort((a,b) => (bTf(a.soldDate) > bTf(b.soldDate))? -1:1)
                    setPaginationLength(sortedItems.length)
                    
                    const renderedItems = sortedItems.slice(pagesViseted, pagesViseted + itemsPerPage).map(item => {
                        const editionId0 = item.tokenId // here it starts from zero
                        return(renderItem(editionId0))
                    })
                    setRenderMarketItems(renderedItems)
            } catch (error) {}
        }

        if(sortByValue === 0) sortByAll(false)
        else if(sortByValue === 1) sortByAll(true)
        else if(sortByValue === 2) sortByPrice(false)
        else if(sortByValue === 3) sortByPrice(true)
        else if(sortByValue === 4) sortByLastSold(false)
        else if(sortByValue === 5) sortByLastSold(true)
        else if(sortByValue === 6) sortByRecentlyListed(false)
        else if(sortByValue === 7) sortByRecentlySold(false)
        else if(sortByValue === 8) sortByRarity(5)
        else if(sortByValue === 9) sortByRarity(4)
        else if(sortByValue === 10) sortByRarity(3)
        else if(sortByValue === 11) sortByRarity(2)
        else if(sortByValue === 12) sortByRarity(1)
        else if(sortByValue === 13) sortByRarity(0)
        else if(sortByValue === 14) sortByBurned()
        else if(sortByValue === 15) sortByRecentlyListed(true)
        else if(sortByValue === 16) sortByRecentlySold(true)
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allListingPrices, allLastSoldPrices, tokenReward, sortByValue, pageNumber, quality, totalMinted])

    const pageCount = Math.ceil(paginationLength / itemsPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }
    const pagination = () => {
        if(paginationLength <= itemsPerPage) return
        return (
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                perRangeDisplayed={1}
                marginPagesDisplayed={1}
                forcePage={pageNumber}
                pageClassName={pageNumber}
            />
        )
    }
    const handleSortByChange = (e) => {
        let value = e.target.value
        setSortByValue(parseInt(value))
        setPageNumber(0)
    }
    const handleRarityChange = (e) => {
        let value = e.target.value
        setSortByValue(parseInt(value))
        setPageNumber(0)
    }
    const increaseQuality = () => {
        setQuality(quality + 100)
    }
    const decreaseQuality = () => {
        if (quality > 100) {
            setQuality(quality - 100)            
        }
    }

    return (
        <div className='mainContainer'>
            <div className="marketInfo">
                <div className="floorPrice">
                    <div className="title">Floor Price</div>
                    <div className="value">
                        <span className="number">
                            {floorPrice ? ethers.utils.formatUnits(floorPrice, 18) : 0}
                        </span>
                        <img src={avaxLogo} alt='' />
                    </div>
                </div>
                <div className="totalVolume">
                    <div className="title">Total Volume</div>
                    <div className="value"><span className="number">{totalVolume ? ethers.utils.formatUnits(totalVolume, 18) : 0}</span><img src={avaxLogo} alt='' /></div>
                </div>
                <div className="dailyVolume">
                    <div className="title">Burn Pool</div>
                    <div className="value"><span className="number">{burnPool?parseFloat(ethers.utils.formatUnits(burnPool, 18)).toFixed(6) : 0 } </span><img src={avaxLogo} alt='' /></div>
                </div>
                <div className="totalSales">
                    <div className="title">Total Sales</div>
                    <div className="value"><span className="number">{totalSales ? totalSales.toString() : 0}</span></div>
                </div>
                <div className="burnedAmount">
                    <div className="title">Total Burned</div>
                    <div className="value"><span className="number">{totalBurned ? totalBurned.toString() : 0}</span></div>
                </div>
                <div className="listedItemsCount">
                    <div className="title">Listings</div>
                    <div className="value"><span className="number">{totalListings ? totalListings.toString() : 0} </span></div>
                </div>
            </div>
            <div className="utils">
                <div className="filter">
                    <div className = 'sortBy'>
                        <select name="sortBy" id="sortByOptions" defaultValue ='0' onChange={handleSortByChange}>
                            <option value="0">All</option>
                            <option value="1">All Listed</option>
                            <option value="2">Price (Low to High)</option>
                            <option value="3">Price (High to Low)</option>
                            <option value="4">Last Sold (Low to High)</option>
                            <option value="5">Last Sold (High to Low)</option>
                            <option value="6">Recently Listed</option>
                            <option value="15">Oldest Listed</option>
                            <option value="7">Recently Sold</option>
                            <option value="16">Oldest Sold</option>
                            <option value="14">Burned</option>
                        </select>
                    </div>
                    <div className="rarity">
                        <select name="rarity" id="RarityOptions" defaultValue ='Disabled' onChange={handleRarityChange}>
                            <option value="Disabled" disabled >Rarity</option>
                            <option value="8">GEN 5</option>
                            <option value="9">GEN 4</option>
                            <option value="10">GEN 3</option>
                            <option value="11">GEN 2</option>
                            <option value="12">GEN 1</option>
                            <option value="13">GEN 0</option>
                        </select>
                    </div>
                </div>
                
                {pagination()}
                <div className="interaction">
                    <button className="decreaseQuality btn" onClick={decreaseQuality}>Decrease Quality</button>
                    <button className="increaseQuality btn" onClick={increaseQuality}>Increase Quality</button>
                </div>
                
            </div>
            <div className='itemsGrid'>
                {renderMarketItems}
            </div>
            {pagination()}
        </div>
    )
}


