import React,{useState, useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import { ethers } from 'ethers'
import Canvas from '../Canvas/Canvas'
import ABI from '../../resources/ABI.json'
import {IoMdClose} from 'react-icons/io'
import avaxLogo from '../../resources/avax.svg'
import data from '../../resources/metadata.json'
import './showRoom.css'
import {mainAddress} from '../contractAddress.js'

export default function ShowRoom() {

    let isMobile = false
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
    }

    const contractAddress = mainAddress

    //! ===================================================================================================================================
    //! initialize all the states
    const [contract, setContract] = useState()
    const [accounts, setAccounts] = useState('')
    const[walletOfOwner,setWalletOfOwner] = useState('')
    const [allAdresses,setAllAddresses] = useState() 
    const [allPrices,setAllPrices] = useState() 
    const [tokenReward, setTokenReward] = useState()
    const [totalReward, setTotalReward] = useState(0)
    const [selectedToken, setSelectedToken] = useState(0)
    const [filteredListedItems,setFilteredListedItems] = useState()
    const [listedItemsAmounts,setListedItemsAmounts] = useState(0)
    
    const [sellPrice, setSellPrice] = useState(0)
    const [displayItems, setDisplayItems] = useState()
    const [listedItems, setListedItems] = useState()
    const [items] = useState(data);
    

     //! pagination variables
     const [pageNumber, setPageNumber] = useState(0)
     const itemsPerPage = 4
     const pagesViseted = pageNumber * itemsPerPage

    //! pagination variables 2
    const [pageNumber2, setPageNumber2] = useState(0)
    const pagesViseted2 = pageNumber2 * itemsPerPage


    const [quality,setQuality] = useState(isMobile?200:400)

    // ? use effect Rendering Order


    //! ===================================================================================================================================
    // * 1- get contract and wallet address from user if connected with MetaMask
    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const accounts = await provider.listAccounts();
                    setAccounts(accounts);

                    const signer = provider.getSigner()
                    const contract = new ethers.Contract(contractAddress, ABI, signer)
                    setContract(contract)

                } catch (error) {}
            }
        }
        init()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //! ===================================================================================================================================
    // * 2- get tokens of owner and get all 5500 tokens addresses, and 5500 tokens prices and all tokens reward listed and unListed
    useEffect(() => {

        const getWalletOfOwner = async () => {
            try {
                const tokens = await contract.walletofOwner(accounts[0])                
                setWalletOfOwner(tokens)
            } catch (error) {}            
        }

        const getAllAddresses = async () =>{
            try {
                const addresses = await contract.getAllAddresses()
                setAllAddresses(addresses)
            } catch (error) {}
        }

        const getAllPrices = async () =>{
            try {
                const prices = await contract.getAllPrices()
                setAllPrices(prices)
            } catch (error) {}
        }

        const getAllRewards = async () => {
            try {
                const rewards = await contract.getAllTokenRewards()
                const rewardSum = await contract.getAllHolderRewards()
                setTokenReward(rewards)
                setTotalReward(rewardSum.toString())
            } catch (error) {}
        }

        getWalletOfOwner()
        getAllAddresses()
        getAllPrices()
        getAllRewards()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[contract,accounts])

    //! ===================================================================================================================================
    // * 3- render owner unlisted NFTS with rewards once tokens reward are loaded
    const sellToken = (token) => {
        document.getElementById('sellConfirmation').style.display = 'flex'
        setSelectedToken(token)
    }
    const claimSingleReward = async (token) => {
        try {
            const hash = await contract.withdrawSingleTokenReward(token);
            console.log(hash);
        } catch (error) {}
    }
    useEffect(() => {
        const getWalletOfOwner = async () => {
            try {
                const displayItems = walletOfOwner.slice(pagesViseted, pagesViseted + itemsPerPage).map( token => {
                    const itemId = token.toString()
                    const editionID = itemId - 1
                    const metaData = items[itemId-1] //? metaData starts from 0 and itemId from 1 so TODO(itemsId -1)
                    return(
                        <div className="item rgb"  key = {editionID}>
                            <div className="canvasContainer" id = {editionID}>
                                <Canvas edition = {editionID} id={editionID.toString()} name={'showCanvas'+editionID} width = {350} height = {350} mirror={false} segments={quality} resize={true}/>
                            </div>
                            <div className ='details'>
                            <div className = 'title'>{metaData.name} <span className="lvl">GEN {metaData.attributes[8].value}</span> </div>
                                <div className = 'interactionSection'>
                                    <span className="reward">
                                        Token Reward: {tokenReward?parseFloat(ethers.utils.formatUnits(tokenReward[editionID]?tokenReward[editionID].toString():0,18)).toFixed(6):'0'} 
                                        <img src = {avaxLogo} alt ='logo'/>
                                    </span>
                                    <span className="buttons">
                                        <span className = 'claimBtn' onClick = {() => claimSingleReward(itemId)}>CLAIM REWARD</span> 
                                    </span>
                                    <span className="buttons">
                                        <span className = 'sellBtn' onClick = {() => sellToken(itemId)}>SELL</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
                setDisplayItems(displayItems)
            } catch (error) {}
        }
        getWalletOfOwner()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[walletOfOwner,tokenReward,pageNumber])

    //! ===================================================================================================================================
    // * 4- render owner listed NFTS with their reward and listing price
    const unlistToken = async (itemId) => {
        try {
            const hash = await contract.cancelListing(itemId)
            console.log(hash);
                
        } catch (error) {}
    }
    useEffect(() => {
        try {
            const getListedNFTsLength = () => {
                let l = 0
                let list = []
                allAdresses.map((address,index) => {
                    const itemId = (index + 1).toString()
                    if (address === accounts[0] && allPrices[itemId-1] > 0) {
                        l++
                        list.push(index)
                    }
                    return null
                })
                setFilteredListedItems(list)
                setListedItemsAmounts(l)
            }    
            getListedNFTsLength()

        } catch (error) {}
    },[allAdresses,allPrices,accounts])
    useEffect(() => {
        const getListingsOfOwner = async () => {
            try {
                const displayItems = filteredListedItems.slice(pagesViseted2, pagesViseted2 + itemsPerPage).map( token => {
                    const itemId = (token + 1).toString()
                    const editionID = itemId - 1
                    const metaData = items[itemId-1]
                    return(
                        <div className="listedItem rgb" key = {editionID}>
                            <div className="canvasContainer" id = {editionID}>
                                <Canvas edition = {editionID} id={editionID.toString()} name={'showCanvas'+editionID} width = {350} height = {350} mirror={false} segments={quality} resize = {true}/>
                            </div>
                            <div className ='details'>
                                <div className = 'title'>{metaData.name} <span className="lvl">GEN {metaData.attributes[8].value}</span> </div>
                                <div className = 'interactionSection'>
                                    <span className="reward">
                                        Token Reward: {tokenReward?parseFloat(ethers.utils.formatUnits(tokenReward[editionID]?tokenReward[editionID].toString():0,18)).toFixed(6):'0'}
                                        <img src = {avaxLogo} alt ='logo'/>
                                    </span>
                                    <span className="sellingPrice">
                                        Listing Price: {allPrices?ethers.utils.formatUnits(allPrices[editionID]?allPrices[editionID].toString():0,18):'0'}
                                        <img src = {avaxLogo} alt ='logo'/>
                                    </span>
                                    <span className="buttons">
                                        <span className = 'unlistBtn' onClick = {() => unlistToken(itemId)}>DELIST</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
                setListedItems(displayItems)
            } catch (error) {}
        }
        getListingsOfOwner()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[tokenReward,allPrices,pageNumber2,filteredListedItems])

    //! ===================================================================================================================================
    const claimReward = async () => {
        try {
            const hash = await contract.withdrawAllTokenRewards()
            console.log(hash);
        } catch (error) {}
    }
    
    const sellPriceChanged = (e) => {
        let value = e.target.value;
        if(value < 0){
            value = ''
            e.target.value = ''
        }
        setSellPrice(value);
    }

    const finalSell = async () => {
        try {
            const parsePrice = ethers.utils.parseEther(sellPrice)
            const hash = await contract.createMarketItem(selectedToken,parsePrice);
            console.log(hash);
        } catch (error) {
            console.log('selling item failed');
        }
    }

    //! ===================================================================================================================================
    // pagination initialization for listed and unlisted items
    const pageCount = Math.ceil(walletOfOwner.length / itemsPerPage)
    const pageCount2 = Math.ceil(listedItemsAmounts / itemsPerPage)
    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }
    const changePage2 = ({ selected }) => {
        setPageNumber2(selected)
    }
    const pagination = () => {
        return (
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                perRangeDisplayed={1}
                marginPagesDisplayed={1}
                forcePage={pageNumber}
                pageClassName={pageNumber}
            />
        )
    }
    const pagination2 = () => {
        return (
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount2}
                onPageChange={changePage2}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                perRangeDisplayed={1}
                marginPagesDisplayed={1}
                forcePage={pageNumber2}
                pageClassName={pageNumber2}
            />
        )
    }


    return (
        <div className ='showRoomBase'>
            <div className="showRoomContainer">

{/* //! Calculate Owner Reward ========================================================================================== */}
                <div className="rewardSection">
                    <div className="mainTitle">
                        YOUR REWARD
                    </div>
                    <div className="claimReward">
                        <div className="rewardAmount">
                            <span className = 'rewardText'>{parseFloat(ethers.utils.formatUnits(totalReward,18)).toFixed(6)}</span>
                            <img src={avaxLogo} alt="logo" />
                        </div>
                        <button onClick ={claimReward}>CLAIM ALL</button>
                        <div className="hiddenText">Claim all unlisted Tokens only</div>
                    </div>
                </div>
{/* //! ALL owner NFTS ================================================================================================= */}
                <div className="tokensSection">
                    <div className="mainTitle">
                        YOUR NFTS
                    </div>
                    {/* {pagination()} */}
                    {walletOfOwner?(walletOfOwner.length > 4) ? pagination() :null :null}
                    <div className="itemsGrid">
                        {displayItems? 
                        (displayItems.some((item) => item !== null) === true)?
                        displayItems:
                        <span className = 'noTokens'>You do not own any NFTS to display</span>
                        :<span className = 'noTokens'>Wallet is not Connected</span>
                        }
                    </div>
                </div>
{/* //! Listed tokens ================================================================================================= */}
                <div className="listedTokensSection">
                    <div className="mainTitle">
                        YOUR LISTINGS
                    </div>
                    {/* {pagination2()} */}
                    {listedItemsAmounts?(listedItemsAmounts > 4) ? pagination2() :null :null}
                    <div className="itemsGrid">
                        {listedItems? 
                        (listedItems.some((item) => item !== null) === true)?
                        listedItems:
                        <span className = 'noTokens'>You do not have any listings to display</span>
                        :<span className = 'noTokens'>Wallet is not Connected</span>
                        }
                    </div>
                </div>

{/* //! popup sell Menue=============================================================================================== */}
                <div id="sellConfirmation">
                    <div className="sellContainer">
                        <div className="iconContainer">
                            <IoMdClose className = "closeIcon" onClick = {() => {document.getElementById('sellConfirmation').style.display = 'none'}}/>
                        </div>
                        <div className="selectedTitle">
                            <span className="leftSection">#{selectedToken}</span>
                            <span className="rightSection">GEN{items[selectedToken - 1]?items[selectedToken - 1].attributes[8].value:null}</span>
                        </div>
                        <div className="interactionSelected">
                            <div className="selectedReward">
                                Token Reward: {
                                tokenReward?
                                parseFloat(ethers.utils.formatUnits(
                                    tokenReward[selectedToken - 1]?
                                    tokenReward[selectedToken - 1].toString()
                                    :0,18)).toFixed(6)
                                :'0'}
                                <img src={avaxLogo} alt="logo" />
                            </div>
                            <input type="number" className="price" placeholder='Selling Price in AVAX (ex: type 1 for 1 AVAX)' onChange={sellPriceChanged}/>
                            <button className="sell" onClick ={finalSell}>SELL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
