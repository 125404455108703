import React,{useEffect,useState} from 'react'
import * as render from './render.js'
import ABI from '../../resources/ABI.json'
import { ethers } from "ethers";
import './museum.css'
import data from '../../resources/metadata.json'
import {mainAddress} from '../contractAddress.js'

export default function Museum() {
    let isMobile = false
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
    }

    const contractAddress = mainAddress
    const [contract, setContract] = useState()
    const [edition,setEdition] = useState(0)
    const [inputValue,setInputValue] = useState(0)
    const [totalMinted, setTotalMinted] = useState(20)
    const [quality,setQuality] = useState(isMobile?{wSegments:300,hSegments:300}:{wSegments:1500,hSegments:1500})
    const [type,setType] = useState(1)
    const [items] = useState(data)

    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const signer = provider.getSigner()
                    const contract = new ethers.Contract(contractAddress, ABI, signer)
                    setContract(contract)
                } catch (error) {}
            }
        }
        init()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getTotalBurnedAndSupply = async () => {//return type: single number
            try {
                const b = await contract.itemsBurned()
                const supply = await contract.totalSupply()
                setTotalMinted(parseInt(b.toString()) + parseInt(supply.toString()))
            } catch (error) {}
        }
        getTotalBurnedAndSupply()
    },[contract])

    useEffect(() => {
        try{
            if (edition < totalMinted) {
                render.init(edition,quality.wSegments,quality.hSegments,type)                
            }
        }
        catch(error){}
        return () => {
            try {
                cancelAnimationFrame(render.animationFrameId)
                render.dispose()
                render.renderer.domElement = null;
                render.renderer.dispose()
            } catch (error) {}
        }
    }, [edition,quality,totalMinted,type])

    

    const increaseEdition = () => {
        if (edition< totalMinted - 1) {
            if (edition < 5499) setEdition(edition + 1) 
        }
    }
    const decreaseEdition = () => {
            if(edition > 0) setEdition(edition - 1)
    }
    const increaseQuality = () => {
        if (quality.wSegments < 500) setQuality({wSegments:quality.wSegments+100,hSegments:quality.hSegments+100})
        else if(quality.wSegments < 3500) setQuality({wSegments:quality.wSegments+500,hSegments:quality.hSegments+500})
    }
    const decreaseQuality = () => {
        if (quality.wSegments > 500) setQuality({wSegments:quality.wSegments-500,hSegments:quality.hSegments-500})            
        else if(quality.wSegments > 100) setQuality({wSegments:quality.wSegments-100,hSegments:quality.hSegments-100})            

    }
    const changeEdition = (e) => {
        let value = e.target.value
        if(value < 1) return
        if(value > 5500) return

        value = parseInt(value) - 1
            setInputValue(value)
    }
    const submitChange = () => {
        if (inputValue < totalMinted) {
            setEdition(inputValue)
        }
    }

    try {
        document.onkeydown = checkKey;
        function checkKey(e) {
            e = e || window.event;
            if (e.keyCode === 38) {
                increaseQuality()
            }
            else if (e.keyCode === 40) {
                decreaseQuality()
            }
            else if (e.keyCode === 37) {
              decreaseEdition()
            }
            else if (e.keyCode === 39) {
              increaseEdition()
            }
  
        }
      } catch (error) {}

    return (
        <div className = 'museumContainer'  id = 'museumContainer'>
            <div className="edition">#{edition + 1}</div>
            <div className="gen">GEN {items[edition].attributes[8].value}</div>
            <div className="interactionSection">
                <div className="row0">
                    <button id="fullScreen" className='btn'>Full Screen</button>
                    <button  className='btn' onClick={() => {type === 1?setType(2):setType(1)}} >{type === 1?'Avarsa S':'Avarsa P'}</button>
                </div>
                <div className="row1">
                    <button onClick={decreaseEdition} id = 'prevBtn' className='btn'>Prev</button>
                    <button onClick={increaseEdition} id = 'nextBtn' className='btn'>Next</button>
                </div>
                <div className="row2">
                    <button onClick = {decreaseQuality}className = 'dBtn btn'>Decrease Quality</button>
                    <button onClick = {increaseQuality} className = 'iBtn btn'>Increase Quality</button>
                </div>
                <div className="row3">
                    <input type="number" onChange={changeEdition} className = 'changeValue' placeholder='Type #edition'/>
                    <button onClick={submitChange} className = 'changeBtn btn'>View</button>
                </div>
                <canvas id = 'museumCanvas'></canvas>
            </div>
            
        </div>
    )
}
