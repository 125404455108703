import React , {useState, useEffect} from 'react'
import ABI from '../../resources/ABI.json'
import { ethers } from "ethers";
import Mint from '../mint/Mint'
import Tokenomics from '../tokenomics/Tokenomics'
import Burn from '../burn/Burn'
import About from '../about/About'
import Rarity from '../Rarity/Rarity'
import RoadMap from '../RoadMap/RoadMap'
import Faq from '../Faq/Faq'
import  AppContext  from '../AppContext'
import Footer from '../footer/Footer';
import {mainAddress} from '../contractAddress.js'
export default function Home() {

    const contractAddress = mainAddress
    const [contract, setContract] = useState()
    const [accounts, setAccounts] = useState('')
    const[walletOfOwner,setWalletOfOwner] = useState('')


    useEffect(() => {
        const init = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const accounts = await provider.listAccounts();
                    setAccounts(accounts);

                    const signer = provider.getSigner()
                    const contract = new ethers.Contract(contractAddress, ABI, signer)
                    setContract(contract)
                    
                } catch (error) {}
            }
        }
        init()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const getWalletOfOwner = async () => {
            try {
                const tokens = await contract.walletofOwner(accounts[0])                
                setWalletOfOwner(tokens)
            } catch (error) {}
        }
        getWalletOfOwner()
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[contract,accounts])
    

    const exports = {
        contract,
        accounts,
        setContract,
        setAccounts,
        walletOfOwner,
    }
    return (
        <AppContext.Provider value={exports}>
            <Mint />
            <About/>
            <Tokenomics />
            <Rarity />
            <RoadMap />
            <Faq />
            <Burn/>
            <Footer/>
        </AppContext.Provider>
    )
}
