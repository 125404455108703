import React from 'react'
import './faq.css'
export default function Faq() {
  return (
    <div>
      <div className="faqBase">
        <div className="title">FAQ</div>
        <div className="faqContainer">

          {/* <div className="questionAnswer">
            <div className="question">
                <span className="text">What is the minting price?</span>
            </div>
            <div className="answer">Minting is closed for now, Price is unkown</div>
          </div> */}

          <div className="questionAnswer">
            <div className="question">
                <span className="text">When does the minting start?</span>
            </div>
            <div className="answer">Friday, 19 November 2021 15:00:00 GMT/UTC</div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">What is the total supply?</span>
            </div>
            <div className="answer">There are 5500 Avarsa to be ever created</div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">How many tokens can I mint?</span>
            </div>
            <div className="answer">You can mint 20 tokens per transaction. If we increase the amount your transaction will likely fail</div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">What do you mean by "rendered realtime"?</span>
            </div>
            <div className="answer">
              Each Avarsa is generated using shaders, these shaders will be sent to the GPU to render your tokens 
              live and make them interactive, you can interact with all pieces and view them in our 3D Museum.
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">Where can I trade my NFTs?</span>
            </div>
            <div className="answer">
            You can trade them on our marketplace. We will list our collection on other marketplaces once they support this new technology. 
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">Where can I claim my token reward?</span>
            </div>
            <div className="answer">
            In the showroom section, you can claim all your unlisted tokens reward or claim token's reward individually
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">How can I list or unlist my NFTs on the marketplace?</span>
            </div>
            <div className="answer">
              You can do that in the showroom section
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">Where can I burn my NFTs?</span>
            </div>
            <div className="answer">
              Just below this section, there is a burn section where you can burn your special tokens and receive the reward
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">Why can't I see some of my special NFTs in the burn section?</span>
            </div>
            <div className="answer">
             If the token was listed on the marketplace you will have to delist it to burn it
            </div>
          </div>

          <div className="questionAnswer">
            <div className="question">
                <span className="text">What happens if the burn pool matches the floor price and I bought the floor price token?</span>
            </div>
            <div className="answer">
             If you buy the token before it gets burned then you will be the new owner and the contract will start locating the next token.
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
