import React, { useState, useEffect, useContext } from 'react'
import AppContext from '../AppContext';
import { ethers } from "ethers";
import Countdown from "react-countdown";
import Canvas from  '../Canvas/Canvas'
import AvaxLogo from '../../resources/avax.svg'
import './mint.css'


// !=========================================================================================

export default function Mint() {

    let quality = 650
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        quality = 250
    }

    const [amount, setAmount] = useState(1)
    const [mintedAmount,setMintedAmount] = useState(0)
    const [totalBurned, setTotalBurned] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [mintingPaused,setMintingPaused] = useState(true)
    const context = useContext(AppContext)
    const price = 0.5
    const releaseDate = 1637334000000// 19/November/2021 , 15:00 GMT//1637334000000
    let canMint = false
    
    useEffect(() => {
        const getMintedAmount = async () => {
            try {
                const amount = await context.contract.totalSupply()
                setMintedAmount(amount)    
            } catch (error) {}
        }
        getMintedAmount()

        const getTotalBurned = async () => {
            try{
                const b = await context.contract.itemsBurned()
                setTotalBurned(b)
            }
            catch(error){}
        }
        const getMintingPaused = async () => {
            try {
                const status = await context.contract.paused()
                setMintingPaused(status)
            } catch (error) {}
        }
        getMintingPaused()
        getTotalBurned()
        setTotalAmount(parseInt(totalBurned.toString()) + parseInt(mintedAmount.toString()))
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[context.contract,mintedAmount.toString(),totalBurned.toString()])


    

    // mint input field
    const handleChange = (e) => {
        let value = e.target.value
        if (parseInt(value) === 0) {
            value = ''
        }
        else if (value > 20) {
            value = 20
        }
        else if(value < 1){
            value = ''
        }
        else{
            value = parseInt(value)
        }
        setAmount(value)
        e.target.value = value
    }

    //Mint button action 
    const mint = async () => {
        if(canMint === false) return
        const totalValue = (amount ? amount : 1) * price
        let overrides = {
            value: ethers.utils.parseEther(totalValue.toString())
        }
        try {
            const hash = await context.contract.mint(context.accounts[0], amount ? amount : 1, overrides)
            console.log(hash);    
        } catch (error) {
            console.log('Minting Failed');
        }
    }

    const freeMint = async () => {
        if(canMint === false) return
        if(totalAmount > 520) return 
        try {
            const hash = await context.contract.free500Mint(context.accounts[0],1)
            console.log(hash);    
        } catch (error) {
            console.log('Minting Failed');
        }
    }
    const freeMintRender = () => {
        let mintedDisplayedAmount = totalAmount - 20
        if(mintedDisplayedAmount < 0 ) mintedDisplayedAmount = 0
        return(
            <div className="freeMintInteraction">
                <button className='freeMintButton' onClick={freeMint} id="mintButton">FREE MINT</button>
                <div className='freeMintedAmount'>{!mintingPaused?mintedDisplayedAmount:0}/500 Minted</div>
            </div>
        )
    }

    const mintRender = () => {
        return (
            <div className="mintInteraction">
                <input type="number" onChange={handleChange} value = {amount}/>
                <button className='mintButton' onClick={mint} id="mintButton">Mint</button>
                <div className='mintedAmount'>
                    {/* {!mintingPaused?
                    totalAmount < 5500?
                    totalAmount
                    :'SOLD OUT'
                    :0
                    } */}
                    {"First round is closed"}
                    {/* {totalAmount < 5500?'/5500 Minted':null}</div> */}
                </div>
            </div>
        )
    }

    const Live = () => <span className="remainingTimer live">Minting is Over</span>

    //render the timer
    const renderer = ({ days,hours, minutes, seconds, completed }) => {
        if (completed) {
            canMint = true
            return (
            <>
                <Live />
                {mintRender()}
            </>
            )
        } else {
            return (
                <span className = 'remainingContainer'>
                <span className="remainingTimer">
                {days}D:{hours}h:{minutes}m:{seconds}s
                </span>
            </span>
            );
        }
    }; 


    return (
        <div className="mintBase">
            <div className='mintContainer'>
                <div className="shaderMainContainer" id = 'shaderPlace'>
                    <Canvas edition = {3} id='shaderPlace' name='mainCanvas' mirror={false} segments={quality} resize={true}/>
                </div>

                <div className="rightSide">
                    <div className="mintInfo">
                        <h3>AVARSAS</h3>
                        <p>Income Generating and Deflationary Realtime Code Generated NFTS
                            Built on <span className='avax'>Avalanche <img src={AvaxLogo} alt="avax" /></span></p>
                    </div>

                    <div className="timer">
                        <Countdown date={releaseDate} renderer={renderer}/> {/*1635778800000 */}
                    </div>
                </div>
            </div>
            
        </div>
    )
}
