import React from 'react'
import Navbar from './components/navbar/Navbar'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import MarketPlace from './components/marketPlace/MarketPlace'
import Home from './components/Home/Home'
import ShowRoom from './components/ShowRoom/ShowRoom'
import Museum from './components/Museum/Museum'
export default function App() {
  return (

    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route path = '/' exact component={Home}/>
        <Route path = '/showroom' exact component ={ShowRoom} />
        <Route path = '/market' exact component={MarketPlace}/>
        <Route path = '/museum' exact component={Museum}/>
        <Route path = '*' exact component={Home}/>
      </Switch>
    </BrowserRouter>
    
  )
}

