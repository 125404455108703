import * as THREE from 'three/build/three.module.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as U from './utils.js'
import data from '../../resources/metadata.json'

export let animationFrameId;
export let renderer
let camera, scene, controls, NFT, particles

export const init = (edition, w, h, type) => {


  const canvas = document.getElementById('museumCanvas')
  const container = document.getElementById('museumContainer')

  const size = {
    width: container.clientWidth,
    height: container.clientHeight,
  }


  scene = new THREE.Scene()
  camera = new THREE.PerspectiveCamera(
    45,
    size.width / size.height,
    1,
    10000,
  )
  camera.position.z = 18
  camera.position.y = 5
  camera.rotateX = Math.PI / 2


  let no = edition
  NFT = U.addNFT(0, 5.1, 0, w, h, no, scene, type)

  //add particles
  let color = `rgb(${parseInt(data[no].attributes[4].value * 100)}%,${parseInt(data[no].attributes[5].value * 100)}%,${parseInt(data[no].attributes[6].value * 100)}%)`
  particles = new U.ParticlesLeaves(color)
  particles.addTo(scene)
  // -------------------------------------------------------------------------------------------------------------------------------
  scene.add(camera)

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
  })

  //rendering
  renderer.setSize(size.width, size.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer.outputEncoding = THREE.sRGBEncoding;
  document.getElementById('museumContainer').appendChild(renderer.domElement)

  // controls
  controls = new OrbitControls(camera, renderer.domElement)
  controls.enablePan = false
  controls.maxDistance = 100
  controls.minDistance = 8
  controls.maxPolarAngle = Math.PI / 2
  controls.update()
  controls.target.set(0, 5, 0)
  camera.lookAt(0, 5, 0)


  U.addFullWindowSupport(canvas)


  let composer, fxaaPass

  U.resizeWindow(size, camera, renderer, composer, fxaaPass)

  let time = Date.now()
  let current0 = Date.now()
  renderer.compile(scene, camera)

  const animate = () => {
    const currentTime = Date.now()
    const deltaTime = currentTime - time
    time = currentTime

    particles.update(deltaTime)

    renderer.render(scene, camera)
    animationFrameId = requestAnimationFrame(animate)
    let current = Date.now()
    let elapsedTime = current - current0;
    NFT.update(elapsedTime)

  }

  U.addMirrorWorld(scene, 0, - Math.PI / 2)
  animate()


}


export const dispose = () => {
  try {
    scene.remove(camera)
    camera = null
    NFT.delete(scene)
    particles.dispose(scene)
    U.disposeMirror(scene)
    scene = null
  } catch (error) { }
}